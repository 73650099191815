import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import request from 'api/request';

import { setIsChat } from 'store/chat';
import { setChatTitle } from 'store/chat';
import { setSelectedUserInfo } from 'store/chat';

import Button from 'components/Button/Button';
import ImgModal from 'components/ImgModal/ImgModal';
import TextModal from 'components/TextModal/TextModal';
import ProfilesImage from 'components/Profile/ProfilesImage';

import IcPhoneBkSVG from 'assets/images/ic-phone-bk.svg';
import IcEmailBkSVG from 'assets/images/ic-email-bk.svg';
import IcCellphoneBkSVG from 'assets/images/ic-cellphone-bk.svg';

import { TabTypeEnum } from './Friends';

interface IMemberTable {
  data: any;
  mutate?: any;
  me?: any;
  adminUserId?: number;
  siteUserId?: number;
  requestsRefetch?: any;
  setSelectedFriendInfo?: any;
  setTabType?: any;
  memberList?: boolean;
}

const FriendListTable = ({
  data,
  mutate,
  requestsRefetch,
  setSelectedFriendInfo,
  setTabType,
  memberList,
  me,
  adminUserId,
  siteUserId,
}: IMemberTable) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectFriendData, setSelectFriendData] = useState<any>(null);
  // const isFieldUser = useIsFieldUser();

  const addHyphen = (v: any) => {
    return v?.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      '$1-$2-$3'
    );
  };

  useEffect(() => {
    if (data !== '') handleFriend();
  }, [data]);

  const handleFriend = async () => {
    await request(
      '/friends',
      'get',
      { 'content-type': 'application/json' },
      { keyword: data?.name }
    )
      .then((res) => {
        setSelectFriendData(res.result[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRequest =
    data?.is_available_approve_friend === true && data?.is_friend === false;
  // const sendRequest =
  //   selectFriendData?.is_available_approve_friend === false &&
  //   selectFriendData?.is_friend === false;
  const isFriend = selectFriendData?.is_friend === true;

  const [isApprovalModalOpen, setIsApprovalModalOpen] =
    useState<boolean>(false);
  const [confirmApproveModal, setConfirmApproveModal] =
    useState<boolean>(false);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [confirmDeleteMember, setConfirmDeleteMember] =
    useState<boolean>(false);
  const [confirmRefuseMember, setConfirmRefuseMember] =
    useState<boolean>(false);

  const handleApproveFriend = async () => {
    setIsApprovalModalOpen(false);
    try {
      await request(
        `/friends/${data.id}`,
        'post',
        {
          'content-type': 'application/json',
        },
        { userId: data.id }
      )
        .then((res) => {
          if (res.data.result) {
            setConfirmApproveModal(true);
            setSelectedFriendInfo(null);
            setTimeout(() => {
              mutate();
              requestsRefetch();
              setTabType(TabTypeEnum.DEFAULT);
            }, 250);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err: any) {
      console.log(err);
      window.alert(err.response?.data.message);
      setIsApprovalModalOpen(false);
    }
  };

  const handleRefuseFriend = async () => {
    setIsRefuseModalOpen(false);
    try {
      await request(
        `/friends/${data.id}`,
        'delete',
        {
          'content-type': 'application/json',
        },
        { userId: data.id }
      )
        .then((res) => {
          if (res.data.result) {
            setConfirmRefuseMember(true);
            setSelectedFriendInfo(null);
            setTimeout(() => {
              mutate();
              requestsRefetch();
              setTabType(TabTypeEnum.DEFAULT);
            }, 250);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err: any) {
      console.log(err);
      window.alert(err.response?.data.message);
      setIsRefuseModalOpen(false);
    }
  };

  const handleDeleteFriend = async () => {
    setIsDeleteModalOpen(false);
    try {
      await request(
        `/friends/${data.id}`,
        'delete',
        {
          'content-type': 'application/json',
        },
        { userId: data.id }
      )
        .then((res) => {
          if (res.data.result) {
            setConfirmDeleteMember(true);
            setSelectedFriendInfo(null);
            setTimeout(() => {
              mutate();
              requestsRefetch();
            }, 250);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
      window.alert('에러 발생..');
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <MemberListContainer>
      {data && (
        <MemberCell>
          <CellLeftSection>
            <ProfilesImage
              width={80}
              height={80}
              fileId={data?.profile_image_id}
              containerStyle={{
                border: 'solid 1px #e3e3e3',
                borderRadius: '100px',
              }}
            />
            <MemberRow>
              <MemberInfoWrap>
                <CompanyInfo>{data?.company?.name}</CompanyInfo>
                <NameInfo>{data?.name}</NameInfo>
                <PositionInfo>{data?.position}</PositionInfo>
              </MemberInfoWrap>
              {me?.id !== data.id && !getRequest ? (
                <MemberButtonWrap>
                  <Button
                    variant=''
                    preset={0}
                    type='button'
                    w={'100'}
                    h={'48'}
                    fSize={'14'}
                    color={isFriend ? 'red' : ''}
                    onClick={() =>
                      isFriend
                        ? setIsDeleteModalOpen(true)
                        : setIsApprovalModalOpen(true)
                    }
                  >
                    {isFriend ? '친구삭제' : '친구추가'}
                  </Button>
                  <span></span>
                  <Button
                    variant=''
                    preset={0}
                    type='button'
                    w={'100'}
                    h={'48'}
                    fSize={'14'}
                    onClick={() => {
                      dispatch(setSelectedUserInfo(data));
                      dispatch(setIsChat(true));
                      dispatch(setChatTitle('c'));
                    }}
                  >
                    채팅하기
                  </Button>
                </MemberButtonWrap>
              ) : null}
            </MemberRow>
            <MemberRow>
              <MemberContactWrap>
                <ContactInfo>
                  <Icon src={IcCellphoneBkSVG} />
                  {addHyphen(data?.phone)}
                </ContactInfo>
                <ContactInfo>
                  <Icon src={IcPhoneBkSVG} />
                  {addHyphen(data?.tel)}
                </ContactInfo>
                <ContactInfo>
                  <Icon src={IcEmailBkSVG} />
                  {data?.username}
                </ContactInfo>
              </MemberContactWrap>
            </MemberRow>
          </CellLeftSection>
          {getRequest ? (
            <AuthorityButtonWrap>
              <Button
                variant=''
                preset={0}
                type='button'
                w={'100'}
                h={'48'}
                fSize={'14'}
                onClick={() => setIsApprovalModalOpen(true)}
              >
                수락
              </Button>
              <span></span>
              <Button
                variant=''
                preset={0}
                type='button'
                w={'100'}
                h={'48'}
                fSize={'14'}
                disabled
                onClick={() => setIsRefuseModalOpen(true)}
              >
                거절
              </Button>
            </AuthorityButtonWrap>
          ) : null}
        </MemberCell>
      )}
      <TextModal
        open={isApprovalModalOpen}
        onClose={() => setIsApprovalModalOpen(false)}
        onSubmit={() => handleApproveFriend()}
        content={
          getRequest
            ? '친구요청을 수락하시겠습니까?'
            : '친구를 추가하시겠습니까?'
        }
        submitText={getRequest ? '수락' : '추가'}
      />
      <TextModal
        open={isRefuseModalOpen}
        onClose={() => setIsRefuseModalOpen(false)}
        onSubmit={() => handleRefuseFriend()}
        content={'친구요청을 거절하시겠습니까?'}
        submitText={'거절'}
      />
      <TextModal
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={() => handleDeleteFriend()}
        content={'친구를 삭제하시겠습니까?'}
        submitText={'삭제'}
      />
      <ImgModal
        open={confirmApproveModal}
        onClose={() => setConfirmApproveModal(false)}
        content={'친구가 추가되었습니다.'}
        submitText={'확인'}
      />
      <ImgModal
        open={confirmDeleteMember}
        onClose={() => setConfirmDeleteMember(false)}
        content={'삭제되었습니다.'}
        submitText={'확인'}
      />
      <ImgModal
        open={confirmRefuseMember}
        onClose={() => setConfirmRefuseMember(false)}
        content={'거절했습니다.'}
        submitText={'확인'}
      />
    </MemberListContainer>
  );
};

const ScrollBox = styled.div`
  /* 스크롤바 설정*/
  &::-webkit-scrollbar {
    width: 17px;
  }

  /* 스크롤바 막대 설정*/
  &::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: #c7c7c7;
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;

    background-clip: padding-box;
    border: 6px solid transparent;
  }

  /* 스크롤바 뒷 배경 설정*/
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
`;

const MemberListContainer = styled(ScrollBox)`
  width: 100%;
  overflow-y: scroll;
`;

const MemberCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  padding: 35px 40px;
`;

const MemberRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const MemberInfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const MemberButtonWrap = styled.div`
  display: flex;
  & > span {
    width: 10px;
  }
`;

const CellLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #777;
  margin-right: 6px;
`;

const NameInfo = styled.div`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.36px;
  color: #000;
  margin-right: 2px;
`;

const PositionInfo = styled.div`
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.32px;
  color: #222;
  margin-top: 3px;
  margin-left: 5px;
`;

const MemberContactWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  font-weight: normal;
  color: #000;

  margin-bottom: 8px;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 14px;
`;

const AuthorityButtonWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > span {
    height: 10px;
  }
`;

export default FriendListTable;
