import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/Layout";

import adminpageSchema from "utils/validation/adminpageSchema";

import Button from "components/Button/Button";
import FileInput from "components/FileInput/FileInput";
import SetErrorBar from "utils/SetErrorBar";

interface IForm {
  resume: [];
}

const defaultValues: IForm = {
  resume: [],
};

const AdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useSelector((s: any) => s.auth);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [pdf, setPdf] = useState<any>([]);
  const [loginInfo, setLoginInfo] = useState<any>();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(adminpageSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();

  useEffect(() => {
    if (userId !== 0) handleUserInfo();
  }, [userId]);

  useEffect(() => {
    if (values.resume.length > 0) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.resume]);

  useEffect(() => {
    setPdf(values.resume);
  }, [values.resume]);

  const handleUserInfo = async () => {
    await request(
      `/users/${userId}`,
      "get",
      { "content-type": "application/json" },
      { userId: userId }
    )
      .then((res) => {
        setLoginInfo(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserInfoModify = async () => {
    let pdfAttachment: any = [];

    if (pdf.length > 0) {
      try {
        const pdffile = pdf[0];
        const res = await request(
          "/files",
          "post",
          { "Content-Type": "multipart/form-data" },
          { file: pdffile }
        );

        pdfAttachment.push(res.data.result.id);
      } catch (err) {
        console.log(err);
      }
    }

    try {
      await request(
        "/resume",
        "post",
        { "content-type": "application/json" },
        { resume_image_id: pdfAttachment[0] }
      )
        .then((res) => {
          if (res.status === 200) {
            navigate("/", { replace: true });
            SetErrorBar("회원정보가 업데이트 되었습니다");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleResumeModify = async () => {
    let pdfAttachment: any = [];

    if (pdf.length > 0) {
      try {
        const pdffile = pdf[0];
        const res = await request(
          "/files",
          "post",
          { "Content-Type": "multipart/form-data" },
          { file: pdffile }
        );

        pdfAttachment.push(res.data.result.id);
      } catch (err) {
        console.log(err);
      }
    }

    try {
      await request(
        `/resume/${location?.state.resumeData.raw_files.id}`,
        "put",
        { "content-type": "application/json" },
        { resume_image_id: pdfAttachment[0] }
      )
        .then((res) => {
          if (res.status === 200) {
            navigate("/resume", { replace: true });
            SetErrorBar("이력서가 수정 되었습니다");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <AdminpageStyled>
        <div className={isMobileWidth ? "mo-mypage-inner" : "mypage-inner"}>
          <h2>관리자 페이지</h2>
          <form
            className={isMobileWidth ? "mo-form-wrap" : "form-wrap"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  if (location?.state.isUpdate) {
                    handleResumeModify();
                  } else {
                    handleUserInfoModify();
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="input-sec">
              <Controller
                control={control}
                name="resume"
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? "250" : ""}
                    accept={".pdf"}
                    filename={
                      location.state.resumeData !== undefined
                        ? location.state.resumeData.raw_files.file_name
                        : ""
                    }
                    fullWidth={false}
                    placeholder={"이력서를 첨부하세요."}
                    errors={errors}
                  />
                )}
              />
            </div>
            {isAbledBtn ? (
              <Button variant="" preset={0} type="submit" fullWidth>
                회원정보 업데이트
              </Button>
            ) : (
              <Button variant="" preset={0} type="submit" disabled fullWidth>
                회원정보 업데이트
              </Button>
            )}
          </form>
        </div>
      </AdminpageStyled>
    </Layout>
  );
};

const AdminpageStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 50px 20px;
  overflow-y: scroll;

  & .mypage-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mo-mypage-inner {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default AdminPage;
