import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import request from "api/request";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/Layout";
import changeAuthSchma from "utils/validation/changeAuthSchma";

import Input from "components/Input/Input";
import Button from "components/Button/Button";
import SetErrorBar from "utils/SetErrorBar";

interface IForm {
  changepw: string;
}

const defaultValues: IForm = {
  changepw: "",
};

const ChangeAuth = () => {
  const navigate = useNavigate();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(changeAuthSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key");

  useEffect(() => {
    if (values.changepw !== "") {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.changepw]);

  const handleChangeAuth = async () => {
    await request(
      `/auth/find-password/${key}`,
      "post",
      { "content-type": "application/json" },
      {
        password: values.changepw,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          SetErrorBar("비밀번호가 변경되었습니다.");
          navigate("/login", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <SearchAuthStyled>
        <div className="searchpw-inner">
          <h2>비밀번호 변경</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleChangeAuth();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="input-sec">
              <Controller
                control={control}
                name="changepw"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="새 비밀번호"
                    errors={errors}
                  />
                )}
              />
            </div>
            {isAbledBtn ? (
              <Button variant="" preset={0} type="submit" fullWidth>
                비밀번호 변경
              </Button>
            ) : (
              <Button variant="" preset={0} type="submit" disabled fullWidth>
                비밀번호 변경
              </Button>
            )}
          </form>
        </div>
      </SearchAuthStyled>
    </Layout>
  );
};

const SearchAuthStyled = styled.div`
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px 0 0 20px;

  & .searchpw-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & form {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default ChangeAuth;
