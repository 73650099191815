import request from "./request";

// 채팅방 메세지 리스트
const chatList = async (chatRoomId: string) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}`,
      "get",
      { "content-type": "application/json" },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 채팅방 멤버 리스트
const chatMemberList = async (chatRoomId: string) => {
  try {
    const data = await request(
      `/chats/${chatRoomId}/members`,
      "get",
      { "content-type": "application/json" },
      {
        chatRoomId: chatRoomId,
      }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  chatList,
  chatMemberList,
};
