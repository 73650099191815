import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import request from 'api/request';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from 'layout/Layout';

interface DataProps {
  files_info: [];
  raw: {
    id: number;
    type: string;
    title: string;
    position: string;
    skils: string;
    detail: string;
    started_at: string;
    end_at: string;
    created_at: string;
    updated_at: string;
    attachments: number[];
  };
}

const WorkDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [resultData, setResultData] = useState<DataProps>();
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    handleGetDetailPortfolio();
  }, []);

  const handleGetDetailPortfolio = async () => {
    await request(
      `/portfolio/${location?.state.portfolioId}`,
      'get',
      {
        'content-type': 'application/json',
      },
      {}
    )
      .then((res) => {
        setResultData(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeletePortfolio = async () => {
    await request(
      `/portfolio/${location?.state.portfolioId}`,
      'delete',
      {
        'content-type': 'application/json',
      },
      {}
    )
      .then(() => {
        navigate(`/works?type=${resultData?.raw.type}`, {
          state: {
            type: resultData?.raw.type,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <WorkDetailStyled>
        <div className='work-header'>
          <h2>Work Detail</h2>
          {accessToken && (
            <div className='btn-group'>
              <div className='registBtn'>
                <button
                  onClick={() =>
                    navigate(`/work-edit/${location?.state.portfolioId}`, {
                      state: {
                        data: resultData,
                      },
                    })
                  }
                >
                  수정
                </button>
              </div>
              <div className='registBtn deleteBtn'>
                <button
                  onClick={() => {
                    const result = window.confirm('게시물을 삭제하시겠습니까?');
                    if (result) {
                      handleDeletePortfolio();
                    }
                  }}
                >
                  삭제
                </button>
              </div>
            </div>
          )}
        </div>
        {isMobileWidth ? (
          <div className='mo-work-detail-section1'>
            {resultData?.raw.attachments && (
              <MoWorkMainImage attachment={resultData?.raw.attachments[0]} />
            )}
            <div className='work-detail-content'>
              <p>
                <span>프로젝트명</span> : {resultData?.raw.title}
              </p>
              <p>
                <span>포&nbsp;&nbsp;&nbsp;지&nbsp;&nbsp;&nbsp;션</span> :{' '}
                {resultData?.raw.position}
              </p>
              <p>
                <span>사&nbsp;용&nbsp;기&nbsp;술</span> :{' '}
                {resultData?.raw.skils}
              </p>
              <p>
                <span>작&nbsp;업&nbsp;기&nbsp;간</span> :{' '}
                {dayjs(resultData?.raw.started_at).format('YYYY.MM.DD')} ~{' '}
                {dayjs(resultData?.raw.end_at).format('YYYY.MM.DD')}
              </p>
            </div>
          </div>
        ) : (
          <div className='work-detail-section1'>
            {resultData?.raw.attachments && (
              <WorkMainImage attachment={resultData?.raw.attachments[0]} />
            )}
            <div className='work-detail-content'>
              <p>
                <span>프로젝트명</span> : {resultData?.raw.title}
              </p>
              <p>
                <span>포&nbsp;&nbsp;&nbsp;지&nbsp;&nbsp;&nbsp;션</span> :{' '}
                {resultData?.raw.position}
              </p>
              <p>
                <span>사&nbsp;용&nbsp;기&nbsp;술</span> :{' '}
                {resultData?.raw.skils}
              </p>
              <p>
                <span>작&nbsp;업&nbsp;기&nbsp;간</span> :{' '}
                {dayjs(resultData?.raw.started_at).format('YYYY.MM.DD')} ~{' '}
                {dayjs(resultData?.raw.end_at).format('YYYY.MM.DD')}
              </p>
            </div>
          </div>
        )}

        <div className='work-detail-section2'>
          <div className='content-wrap'>
            <span className='content-title'>작&nbsp;업&nbsp;내&nbsp;용</span> :{' '}
            <br />
            {resultData?.raw.detail.split('\n').map((line, i) => {
              return (
                <span key={i}>
                  {line}
                  <br />
                </span>
              );
            })}
          </div>
          <div>
            <p className='content-files-title'>
              <span>첨&nbsp;부&nbsp;파&nbsp;일</span> :
            </p>
            {resultData?.raw.attachments &&
              resultData?.files_info.map((x: any, i: number) => {
                const rgx = ['png', 'jpg', 'jpeg'];
                if (rgx.includes(x.extension)) {
                  return (
                    <div key={i}>
                      <img
                        src={`${process.env.REACT_APP_API}/files/${x.id}`}
                        alt='이미지'
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={i}>
                      <video
                        width='100%'
                        controls
                        controlsList='nodownload'
                        src={`${process.env.REACT_APP_API}/files/${x.id}`}
                      ></video>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </WorkDetailStyled>
    </Layout>
  );
};

const WorkDetailStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 0 50px 20px;
  overflow-y: scroll;

  .work-header {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 20px;
  }

  & .work-detail-section1 {
    display: flex;
    width: 98%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
  }

  & .mo-work-detail-section1 {
    display: flex;
    flex-direction: column;

    padding-bottom: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #000;
    span {
      display: block;
    }
  }

  & .work-detail-section2 {
    width: 98%;
    img {
      width: 100%;
    }
  }

  .content-files-title span {
    font-weight: bold;
  }

  .content-wrap {
    margin-bottom: 20px;
  }

  .content-title {
    font-weight: bold;
  }

  & .work-detail-content {
    p {
      font-size: 18px;
      margin-bottom: 10px;
    }
    span {
      font-weight: bold;
    }
  }

  & .btn-group {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  }

  & .registBtn {
    display: flex;
    margin-right: 15px;

    button {
      cursor: pointer;
      width: 80px;
      height: 35px;
      color: #fff;
      border-radius: 4px;
      background-color: #1f1852;
    }
  }

  & .deleteBtn button {
    background-color: #e40000;
  }
`;

const WorkMainImage = styled.div`
  width: 500px;
  height: 300px;
  border-radius: 8px;
  margin-right: 20px;

  background-position: center;
  background-size: cover;
  background-image: ${(props: { attachment: number }) =>
    `url(${process.env.REACT_APP_API}/files/${props.attachment})`};
`;

const MoWorkMainImage = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;

  background-position: center;
  background-size: cover;
  background-image: ${(props: { attachment: number }) =>
    `url(${process.env.REACT_APP_API}/files/${props.attachment})`};
`;

export default WorkDetail;
