import request from "./request";

interface LoginProps {
  userEmail: string;
  userPassword: string;
  autoLogin: boolean;
  rememberId: boolean;
}

// 로그인
const login = async ({
  userEmail,
  userPassword,
  autoLogin,
  rememberId,
}: LoginProps) => {
  try {
    const data = await request(
      "/auth/login",
      "post",
      { "content-type": "application/json" },
      {
        username: userEmail,
        password: userPassword,
      }
    );

    if (data.status === 200) {
      const targetStorage = autoLogin ? localStorage : sessionStorage;

      targetStorage.setItem("accessToken", data.data.access_token);
      targetStorage.setItem("refreshToken", data.data.refresh_token);
    }

    if (rememberId) {
      localStorage.setItem("id", userEmail);
    } else {
      localStorage.removeItem("id");
    }

    return data;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};

// 로그아웃
const logout = () => {
  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  targetStorage.removeItem("accessToken");
  targetStorage.removeItem("refreshToken");
};

// 유저 정보 가져오기
const loginInfo = async () => {
  try {
    const data = await request(
      "/auth/login",
      "get",
      { "content-type": "application/json" },
      {}
    );

    return data;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};

// 유저 권한 관련
const permission = async (userId: number) => {
  try {
    const data = await request(
      "/permissions",
      "get",
      { "content-type": "application/json" },
      { id: userId }
    );

    return data;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};

export default {
  login,
  logout,
  loginInfo,
  permission,
};
