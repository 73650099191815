import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUiValue } from 'store/ui';

enum TabTypeEnum {
  DEFAULT,
  REACT,
  NODE,
  RESEARCH,
  RESUME,
  FRIENDS,
}

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentPosition } = useSelector((s: any) => s.ui);
  const [tabType, setTabType] = useState<TabTypeEnum>(TabTypeEnum.DEFAULT);

  useEffect(() => {
    switch (location?.pathname + location?.search) {
      case '/':
        setTabType(TabTypeEnum.REACT);
        break;
      case '/works?type=REACT':
        setTabType(TabTypeEnum.REACT);
        break;
      case '/works?type=NODE':
        setTabType(TabTypeEnum.NODE);
        break;
      case '/works?type=RESEARCH':
        setTabType(TabTypeEnum.RESEARCH);
        break;
      case '/resume':
        setTabType(TabTypeEnum.RESUME);
        break;
      case '/friends':
        setTabType(TabTypeEnum.FRIENDS);
        break;
      default:
        setTabType(TabTypeEnum.DEFAULT);
        break;
    }
  }, [location]);

  return (
    <SidebarStyled>
      <h2>{currentPosition}</h2>
      <ul className='sidebar-list'>
        <SideMenuStyled
          active={tabType === TabTypeEnum.REACT}
          onClick={() => {
            navigate('/works?type=REACT', {
              state: {
                type: 'REACT',
              },
            });
            dispatch(setUiValue('Works'));
          }}
        >
          React
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === TabTypeEnum.NODE}
          onClick={() => {
            navigate('/works?type=NODE', {
              state: {
                type: 'NODE',
              },
            });
            dispatch(setUiValue('Works'));
          }}
        >
          Node
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === TabTypeEnum.RESEARCH}
          onClick={() => {
            navigate('/works?type=RESEARCH', {
              state: {
                type: 'RESEARCH',
              },
            });
            dispatch(setUiValue('Works'));
          }}
        >
          Research
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === TabTypeEnum.RESUME}
          onClick={() => {
            navigate('/resume');
            dispatch(setUiValue('Resume'));
          }}
        >
          Resume
        </SideMenuStyled>
        <SideMenuStyled
          active={tabType === TabTypeEnum.FRIENDS}
          onClick={() => {
            navigate('/friends');
            dispatch(setUiValue('Friends'));
          }}
        >
          Friends
        </SideMenuStyled>
      </ul>
    </SidebarStyled>
  );
};

const SidebarStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: calc(100vh - 80px);

  h2 {
    font-weight: bold;
    margin: 60px 0 10px 30px;
  }

  .sidebar-list {
    width: 80%;
    margin: 0 auto;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-bottom: 1px solid #d9d9d9;
      cursor: pointer;

      &:hover {
        color: #fff;
        background-color: #1f1852;
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
`;
const SideMenuStyled = styled.li<{ active: boolean }>`
  ${({ active }) =>
    active
      ? css`
          color: #fff;
          background-color: #1f1852;
          border-bottom: 1px solid #d9d9d9;
        `
      : css`
          color: #000;
          background-color: #fff;
          border-bottom: 1px solid #d9d9d9;
        `}
`;

export default SideBar;
