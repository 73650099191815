import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

type CustomResponseFormat<T = any> = {
  response: T;
  refreshedToken?: string;
};

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<CustomResponseFormat>>;
  };
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API,
};

const instance: CustomInstance = axios.create(axiosConfig);

instance.interceptors.request.use(
  async (req) => {
    const targetStorage = localStorage.getItem('accessToken')
      ? localStorage
      : sessionStorage;
    const accessToken = targetStorage.getItem('accessToken');

    // @ts-ignore
    if (accessToken) req.headers.authorization = `Bearer ${accessToken}`;

    return req;
  },
  async (err) => {
    // console.log(err);
    return Promise.reject(err);
  }
);

// let isTokenRefreshing: boolean = false;
// let refreshSubscribers: never[] = [];

// const onTokenRefreshed = (accessToken: string) => {
//   refreshSubscribers.map((callback: any) => callback(accessToken));
//   refreshSubscribers = [];
// };

// const addRefreshSubscriber = (callback: never) => {
//   refreshSubscribers.push(callback);
// };

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalRequest = err.config;

    if (err.response.status === 401) {
      // if (!isTokenRefreshing) {
      // isTokenRefreshing = true;
      // const targetStorage = localStorage.getItem('refreshToken')
      //   ? localStorage
      //   : sessionStorage;
      // const refreshToken = targetStorage.getItem('refreshToken');
      // await axios({
      //   method: 'get',
      //   url: '/refresh',
      //   headers: {
      //     refresh: refreshToken,
      //   },
      // })
      //   .then((res) => {
      //     targetStorage.setItem('accessToken', res.data.data.accessToken);
      //     targetStorage.setItem('refreshToken', res.data.data.refreshToken);
      //     isTokenRefreshing = false;
      //     axios.defaults.headers.common.authorization = `Bearer${res.data.data.accessToken}`;
      //     onTokenRefreshed(res.data.data.accessToken);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // }
      // const retryOriginalRequest = new Promise((resolve) => {
      //   // @ts-ignore
      //   addRefreshSubscriber((accessToken: any) => {
      //     originalRequest.headers.authorization = `Bearer${accessToken}`;
      //     resolve(axios(originalRequest));
      //   });
      // });
      // return retryOriginalRequest;
    }

    return Promise.reject(err);
  }
);

export default instance;
