import { useState, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import SearchInput from 'components/Input/SearchInput';
import FriendCard from './FriendCard';

enum TabTypeEnum {
  DEFAULT,
  REQUEST,
}

const FriendSideBar = ({
  data,
  selectedFriendInfo,
  setSelectedFriendInfo,
  mutate,
  friendRequests,
  requestsMutate,
  tabType,
  setTabType,
}: any) => {
  const [search, setSearch] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const { isMobileWidth } = useSelector((s: any) => s.ui);

  const isRequested = tabType === TabTypeEnum.REQUEST;

  const searchedFriend: any[] = useMemo(() => {
    if (!data || !friendRequests) return [];
    if (!isRequested) {
      return data?.filter((v: any) => v?.name?.includes(search));
    } else {
      return friendRequests?.filter((v: any) => v?.name?.includes(search));
    }
  }, [isRequested, data, friendRequests, tabType, search]);

  const handleChangeTabType = (type: TabTypeEnum) => {
    setTabType(type);
    setSelectedFriendInfo(null);
    setIsMounted(false);
  };

  return (
    <FriendSideBarStyled isMobile={isMobileWidth}>
      <div className='space-filter'>
        <SearchInput
          placeholder={'검색어를 입력해 주세요.'}
          containerStyle={{ marginBottom: 26 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='tab-container'>
          <Tab
            active={tabType === TabTypeEnum.DEFAULT}
            onClick={() => handleChangeTabType(TabTypeEnum.DEFAULT)}
          >
            회원목록 ({data?.length || 0})
          </Tab>
          <Tab
            active={tabType === TabTypeEnum.REQUEST}
            onClick={() => handleChangeTabType(TabTypeEnum.REQUEST)}
          >
            받은요청 ({friendRequests?.length || 0})
          </Tab>
        </div>
      </div>
      <div className='searched-space'>
        {searchedFriend?.map((v: any, i: number) => (
          <FriendCard
            key={v?.id}
            info={v}
            id={v?.id}
            name={v?.name}
            position={v?.position}
            revalidate={mutate}
            isHide={tabType === TabTypeEnum.REQUEST}
            active={v.id === selectedFriendInfo?.id}
            onClick={() => setSelectedFriendInfo(v)}
          />
        ))}
      </div>
    </FriendSideBarStyled>
  );
};

const FriendSideBarStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;

  height: 720px;
  max-height: 720px;
  background-color: white;

  & .space-filter {
    display: block;
    height: 136px;
    padding: 30px 30px 0px 30px;
    border-bottom: 1px solid #e3e3e3;
  }

  & .tab-container {
    display: flex;
    width: 100%;
  }

  & .searched-space {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 350px;
          max-width: 350px;
          .searched-space {
            padding: 30px 20px 20px 20px;
          }
        `
      : css`
          width: 360px;
          max-width: 360px;
          .searched-space {
            padding: 30px 6px 20px 20px;
          }
        `}
`;

const Tab = styled.div<{ active: boolean }>`
  flex: 1;
  padding: 3px 0px 10px 0px;
  text-align: center;
  margin-bottom: -1px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          font-size: 15px;
          font-weight: 500;
          letter-spacing: -0.3px;
          color: #222;

          border-bottom: 2px solid black;
        `
      : css`
          font-size: 15px;
          letter-spacing: -0.3px;
          color: #999;
        `}
`;
export default FriendSideBar;
