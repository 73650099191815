import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import request from 'api/request';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Layout from 'layout/Layout';
import searchAuthSchma from 'utils/validation/searchAuthSchma';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import SetErrorBar from 'utils/SetErrorBar';

interface IForm {
  email: string;
}

const defaultValues: IForm = {
  email: '',
};

const SearchAuth = () => {
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(searchAuthSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (values.email !== '') {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.email]);

  const handleSearchAuth = async () => {
    await request(
      '/auth/find-password',
      'post',
      { 'content-type': 'application/json' },
      {
        email: values.email,
      }
    )
      .then((res) => {
        if (res.status === 200) {
          SetErrorBar('비밀변호 변경 링크가 전송되었습니다.');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <SearchAuthStyled>
        <div className='searchpw-inner'>
          <h2>비밀번호 찾기</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleSearchAuth();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='이메일'
                    errors={errors}
                  />
                )}
              />
            </div>
            {isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                비밀번호 찾기
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                비밀번호 찾기
              </Button>
            )}
          </form>
        </div>
      </SearchAuthStyled>
    </Layout>
  );
};

const SearchAuthStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 20px 0 20px;

  & .searchpw-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .mo-form-wrap {
    width: 100%;
  }

  & .input-sec {
    margin: 10px 0;
  }
`;

export default SearchAuth;
