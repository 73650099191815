/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const MypageMenu = () => {
  const navigate = useNavigate();
  const root = rootCss();

  return (
    <div css={root}>
      <div className='root-inner'>
        <div
          className='mypage-menu'
          onClick={() => {
            navigate('/mypage');
          }}
        >
          회원정보 수정
        </div>
        <div className='line'></div>
        <div
          className='mypage-menu'
          onClick={() => {
            navigate('/adminpage', {
              state: {
                isUpdate: false,
              },
            });
          }}
        >
          관리자 페이지
        </div>
      </div>
    </div>
  );
};

const rootCss = () => {
  return css`
    position: absolute;
    z-index: 9999999999;
    top: 80px;
    right: 60px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 5px 5px 5px #d9d9d9;

    .root-inner {
      width: 200px;
      .line {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #e3e3e3;
        margin-right: 68px;
      }

      .mypage-menu {
        padding: 15px 20px;
        cursor: pointer;
      }
    }
  `;
};

export default MypageMenu;
