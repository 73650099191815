import { useState, useMemo, useEffect } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import request from 'api/request';
import { useSelector } from 'react-redux';

import Layout from 'layout/Layout';

import FriendSideBar from './FriendSideBar';
import FriendListTable from './FriendListTable';
import MobileFriendListTable from './MobileFriendListTable';

export enum TabTypeEnum {
  DEFAULT,
  REQUEST,
}

const Friends = () => {
  const [selectedFriendInfo, setSelectedFriendInfo] = useState<any>(null);
  const [tabType, setTabType] = useState<TabTypeEnum>(TabTypeEnum.DEFAULT);
  const [isMounted, setIsMounted] = useState(false);
  const [friendsData, setFriendsData] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const { isMobileWidth } = useSelector((s: any) => s.ui);

  useEffect(() => {
    handleFriendsList();
    handleFriendsRequest();
  }, []);

  const handleFriendsList = async () => {
    await request(
      '/friends/user_list',
      'get',
      { 'content-type': 'application/json' },
      {}
    )
      .then((res) => {
        setFriendsData(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFriendsRequest = async () => {
    await request(
      '/friends/requests',
      'get',
      { 'content-type': 'application/json' },
      {}
    )
      .then((res) => {
        setFriendRequests(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isApproved: any = useMemo(() => {
    if (!friendRequests) return;
    return friendRequests?.filter((v: any) => v.is_friend === true);
  }, [friendRequests]);

  return (
    <Layout>
      <FriendsStyled isMobile={isMobileWidth}>
        <h2>친구관리</h2>
        <div className='member-wrap'>
          <div className='sidebar-sec'>
            <FriendSideBar
              data={friendsData}
              selectedFriendInfo={selectedFriendInfo}
              setSelectedFriendInfo={setSelectedFriendInfo}
              mutate={handleFriendsList}
              friendRequests={isApproved?.length > 0 ? [] : friendRequests}
              requestsMutate={handleFriendsRequest}
              tabType={tabType}
              setTabType={setTabType}
            />
          </div>
          {isMobileWidth ? (
            <div>
              <MobileFriendListTable
                data={selectedFriendInfo}
                setSelectedFriendInfo={setSelectedFriendInfo}
                mutate={handleFriendsList}
                requestsRefetch={handleFriendsRequest}
                setTabType={setTabType}
              />
            </div>
          ) : (
            <div className='right-sec'>
              <FriendListTable
                data={selectedFriendInfo}
                setSelectedFriendInfo={setSelectedFriendInfo}
                mutate={handleFriendsList}
                requestsRefetch={handleFriendsRequest}
                setTabType={setTabType}
              />
            </div>
          )}
        </div>
      </FriendsStyled>
    </Layout>
  );
};

const FriendsStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 0 0 20px;
  overflow-y: scroll;

  h2 {
    display: block;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.36px;
    text-align: left;
    color: #222;
    margin-bottom: 20px;
  }

  & .member-wrap {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-bottom: 1px solid #e3e3e3;
  }

  & .sidebar-sec {
    width: 360px;
  }

  & .right-sec {
    width: 100%;
    height: 720px;
    max-height: 720px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e3e3e3;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          .member-wrap {
            margin-right: 40px;
          }
        `
      : css`
          .member-wrap {
            margin-right: 40px;
          }
        `}
`;

export default Friends;
