import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    selectedUserInfo: undefined,
    isChat: false,
    isChatTitle: 'f',
  },
  reducers: {
    setSelectedUserInfo: (state, action) => {
      state.selectedUserInfo = action.payload;
    },
    setIsChat: (state, action) => {
      state.isChat = action.payload;
    },
    setChatTitle: (state, action) => {
      state.isChatTitle = action.payload;
    },
  },
});

export const { setSelectedUserInfo, setIsChat, setChatTitle } =
  chatSlice.actions;

export default chatSlice.reducer;
