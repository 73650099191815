/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

interface ButtonProps {
  w?: string;
  h?: string;
  fSize?: string;
  color?: string;
  variant: string;
  preset: number;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  fullWidth?: boolean;
}

const Button = (props: ButtonProps) => {
  const { variant, preset, type, onClick, disabled, children, fullWidth } =
    props;

  const root = rootCss(props);

  return (
    <button
      className='component-button'
      css={root}
      type={type}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
};

const rootCss = (props: ButtonProps) => {
  const width = props.w ? `${props.w}px` : '200px';
  const color = props.color ? `${props.color}` : '#1f1852';

  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props.fullWidth ? '100%' : width};
    height: ${props.h ? `${props.h}px` : '65px'};
    font-size: ${props.fSize ? props.fSize : '18px'};
    color: ${props.disabled ? '#fff' : '#fff'};
    background-color: ${props.disabled ? '#434343' : color};
    border: solid 1px #1f1852;
    border-radius: 8px;
    cursor: pointer;
  `;
};

export default Button;
