import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import request from 'api/request';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import Layout from 'layout/Layout';

import registEditSchma from 'utils/validation/registEditSchma';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';
import ErrorsMessage from 'components/ErrorsMessage/ErrorsMessage';

import icCencleSVG from 'assets/images/close.png';

import 'react-datepicker/dist/react-datepicker.css';

interface IForm {
  title: string;
  position: string;
  skill: string;
  startdate: Date;
  enddate: Date;
  contents: string;
  files: File[];
}

const defaultValues: IForm = {
  title: '',
  position: '',
  skill: '',
  startdate: new Date(),
  enddate: new Date(),
  contents: '',
  files: [],
};

const WorkEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [img, setImg] = useState<any>([]);
  const [skillArr, setSkillArr] = useState<string[]>();
  const [fileInfoData, setFileInfoData] = useState<any>([]);
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(registEditSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    setFileInfoData(location?.state.data.files_info);
  }, []);

  useEffect(() => {
    setValue('title', location?.state.data.raw.title);
    setValue('position', location?.state.data.raw.position);
    setValue('skill', location?.state.data.raw.skils);
    setValue(
      'startdate',
      new Date(dayjs(location?.state.data.raw.started_at).format('YYYY.MM.DD'))
    );
    setValue(
      'enddate',
      new Date(dayjs(location?.state.data.raw.end_at).format('YYYY.MM.DD'))
    );
    setValue('contents', location?.state.data.raw.detail);
  }, []);

  useEffect(() => {
    const arr = values.skill.split(',');
    setSkillArr(arr);
    setImg(values.files);
  }, [values.skill, values.files]);

  const handleSubmitForm = async () => {
    let attachment: any = [];
    for (let i = 0; i < fileInfoData.length; i++) {
      attachment.push(fileInfoData[i].id);
    }

    if (img) {
      try {
        for (let i = 0; i < img.length; i++) {
          const file = img[i][0];
          const res = await request(
            '/files',
            'post',
            { 'Content-Type': 'multipart/form-data' },
            { file: file }
          );

          attachment.push(res.data.result.id);
        }
      } catch (error) {
        console.log(error);
      }
    }

    let payload: any = {
      type: location?.state.type,
      title: values.title,
      position: values.position,
      skils: values.skill,
      started_at: values.startdate,
      end_at: values.enddate,
      detail: values.contents,
    };
    if (attachment) {
      payload = {
        ...payload,
        attachments: attachment,
      };
    }

    try {
      await request(
        `/portfolio/${location?.state.data.raw.id}`,
        'put',
        { 'content-type': 'application/json' },
        payload
      )
        .then(() => {
          navigate(`/works?type=${location?.state.data.raw.type}`, {
            state: {
              type: location?.state.data.raw.type,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleExceptFile = (exceptId: number) => {
    const filteredData = fileInfoData.filter((v: any) => {
      return v.id !== exceptId;
    });

    setFileInfoData(filteredData);
  };

  return (
    <Layout>
      <WorkRegistStyled>
        <div className='regist-inner'>
          <h2>프로젝트 수정</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleSubmitForm();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='title'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='프로젝트 명'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 포지션'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='skill'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 스킬'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='datepicker-sec'>
              <Controller
                control={control}
                name='startdate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker datepicker1'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => {
                      field.onChange(date);
                    }}
                    placeholderText='시작일'
                  />
                )}
              />
              <span></span>
              <Controller
                control={control}
                name='enddate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => field.onChange(date)}
                    placeholderText='마감일'
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='contents'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    fullWidth={false}
                    multiline={true}
                    useMaxLengthIndicator={true}
                    maxLength={1000}
                    placeholder='프로젝트 내용'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <p className='img-text'>등록한 첨부파일</p>
              <div className='img-sec'>
                {location?.state.data.raw.attachments &&
                  fileInfoData.map((e: any, i: number) => {
                    const rgx = ['png', 'jpg', 'jpeg'];
                    if (rgx.includes(e.extension)) {
                      return (
                        <div key={i}>
                          <img
                            className='files_info_attach'
                            src={`${process.env.REACT_APP_API}/files/${e.id}`}
                            alt={`첨부파일${i}`}
                          />
                          <button
                            type='button'
                            className='files_info_attach_delete'
                            onClick={() => handleExceptFile(e.id)}
                          >
                            <img src={icCencleSVG} alt='' />
                          </button>
                        </div>
                      );
                    } else {
                      return (
                        <div key={i}>
                          <video
                            width='100%'
                            className='files_info_attach'
                            controlsList='nodownload'
                            src={`${process.env.REACT_APP_API}/files/${e.id}`}
                          ></video>
                          <button
                            type='button'
                            className='files_info_attach_delete'
                            onClick={() => handleExceptFile(e.id)}
                          >
                            <img src={icCencleSVG} alt='' />
                          </button>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
            <div className='sub-title'>
              첨부파일<span>(최대 7개까지 등록 가능합니다.)</span>
            </div>
            <div className='file-upload-wrap'>
              <Controller
                control={control}
                name='files'
                render={({ field }) => (
                  <FileUpload
                    img={field.value}
                    setImg={(e: any) => field.onChange(e)}
                    buttonStyle={uploadButton}
                    icon={'ic-plus'}
                    limit={7}
                    disabledPreview={false}
                  />
                )}
              />
            </div>
            {errors.files && <ErrorsMessage errors={errors} name='files' />}
            <Button variant='' preset={0} type='submit' fullWidth>
              수정하기
            </Button>
          </form>
        </div>
      </WorkRegistStyled>
    </Layout>
  );
};

const WorkRegistStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 40px 20px 60px 20px;
  overflow-y: scroll;

  & .regist-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .datepicker-sec {
    display: flex;
    margin: 10px 0;
    span {
      width: 20px;
    }
  }

  & .input-datepicker {
    width: 100%;
    height: 65px;
    padding-left: 20px;
    font-size: 16px;
    border: solid 1px #ddd;
  }

  & .sub-title {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #222;
    margin-top: 15px;
    margin-bottom: 10px;

    span {
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #999;
      margin-left: 4px;
    }
  }

  & .file-upload-wrap {
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    margin-bottom: 20px;
  }

  & .img-text {
    margin-bottom: 10px;
  }

  & .img-sec {
    display: flex;
    flex-wrap: wrap;
    .files_info_attach {
      width: 100px;
      height: 100px;
      border-radius: 12px;
      background-color: #eff7ff;
      background-position: center;
      background-size: cover;
      margin-right: 15px;
    }
  }

  .files_info_attach_delete {
    position: relative;
    top: -102px;
    left: 78px;
    border-radius: 5px;
    background-color: #fff;
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      padding: 2px;
      cursor: pointer;
      width: 15px;
    }
  }
`;

const uploadButton = {
  width: '100px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20 0',
  borderRadius: '12px',
  border: 'solid 1px #000',
  backgroundColor: '#fff',
  marginRight: '20px',
};

export default WorkEdit;
