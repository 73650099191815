import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setIsAlert } from 'store/alert';
import { setUiValue, setIsMobile } from 'store/ui';
import { setIsChat, setSelectedUserInfo } from 'store/chat';
import {
  setAuthValue,
  setUserIdValue,
  setPrincipal,
  setIsMypage,
  setPermission,
} from 'store/auth';

import AuthApi from 'api/auth';

import useWindowSize from 'hooks/useWindowSize';

import AuthGuard from 'components/Guard/AuthGuard';
import PermissionGuard from 'components/Guard/PermissionGuard';

import Main from 'pages/Main/Main';
import Works from 'pages/Works/Works';
import WorkDetail from 'pages/Works/WorkDetail';
import WorkRegist from 'pages/Works/WorkRegist';
import WorkEdit from 'pages/Works/WorkEdit';
import About from 'pages/About/About';
import Resume from 'pages/Resume/Resume';
import Contact from 'pages/Contact/Contact';
import Friends from 'pages/Friends/Friends';
import Mypage from 'pages/Mypage/Mypage';
import AdminPage from 'pages/Mypage/AdminPage';
import Sign from 'pages/Signup/Sign';
import Login from 'pages/Signup/Login';
import SearchAuth from 'pages/Signup/SearchAuth';
import ChangeAuth from 'pages/Signup/ChangeAuth';
import Error404 from 'pages/Error404/Error404';

import 'animate.css';

let currentPath = '';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { userId } = useSelector((s: any) => s.auth);
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  // 화면사이즈 변동 감지
  useEffect(() => {
    if (width < 800) {
      dispatch(setIsMobile(true));
    } else {
      dispatch(setIsMobile(false));
    }
  }, [width, dispatch]);

  // 라우터 이동 시 페이지 새로고침
  useEffect(() => {
    if (currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;

    let path = currentPath;
    if (currentPath === '/') path = '/Home';
    const setPath = path
      .split('/')[1]
      .replace(/\b[a-z]/, (letter) => letter.toUpperCase());

    dispatch(setIsChat(false));
    dispatch(setIsAlert(false));
    dispatch(setIsMypage(false));
    dispatch(setUiValue(setPath));
    dispatch(setSelectedUserInfo(undefined));
  }, [location, dispatch]);

  useEffect(() => {
    if (accessToken) {
      AuthApi.loginInfo()
        .then((res) => {
          dispatch(setPrincipal(res.result));
          dispatch(setAuthValue(res.result.name));
          dispatch(setUserIdValue(res.result.id));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && userId !== 0) {
      AuthApi.permission(userId)
        .then((res) => {
          dispatch(setPermission(res.result.ADMIN_ACCEPT));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [accessToken, userId]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/works' element={<Works />} />
        <Route path='/works/:id' element={<WorkDetail />} />
        <Route
          path='/work-regist'
          element={
            <AuthGuard>
              <WorkRegist />
            </AuthGuard>
          }
        />
        <Route
          path='/work-edit/:id'
          element={
            <AuthGuard>
              <WorkEdit />
            </AuthGuard>
          }
        />
        <Route path='/about' element={<About />} />
        <Route path='/resume' element={<Resume />} />
        <Route path='/contact' element={<Contact />} />
        <Route
          path='/friends'
          element={
            <AuthGuard>
              <Friends />
            </AuthGuard>
          }
        />
        <Route
          path='/mypage'
          element={
            <AuthGuard>
              <Mypage />
            </AuthGuard>
          }
        />
        <Route
          path='/adminpage'
          element={
            <PermissionGuard>
              <AdminPage />
            </PermissionGuard>
          }
        />
        <Route path='/sign' element={<Sign />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login/searchpw' element={<SearchAuth />} />
        <Route path='/login/chagepw' element={<ChangeAuth />} />
        <Route path='/404' element={<Error404 />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
