import * as yup from 'yup';

export default yup.object().shape({
  title: yup.string().required('프로젝트 제목을 입력하세요.'),
  position: yup.string().required('포지션을 입력하세요.'),
  skill: yup.string().required('스킬을 입력하세요.'),
  startdate: yup.string().required('작업 시작 기간을 선택하세요.'),
  enddate: yup.string().required('작업 종료 기간을 선택하세요.'),
  contents: yup.string().required('작업 내용을 입력하세요.'),
  files: yup
    .array()
    .min(1, '첨부파일을 1개 이상 등록하세요.')
    .max(7, '첨부파일은 7개까지만 등록가능합니다.'),
});
