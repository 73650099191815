import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import authApi from 'api/auth';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Layout from 'layout/Layout';
import loginSchma from 'utils/validation/loginSchma';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import SetErrorBar from 'utils/SetErrorBar';

interface IForm {
  userId: string;
  userPw: string;
  autoLogin: boolean;
  rememberId: boolean;
}

const defaultValues: IForm = {
  userId: '',
  userPw: '',
  autoLogin: false,
  rememberId: false,
};

const Login = () => {
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [isSaveId, setIsSaveId] = useState<boolean>(false);
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const saveId = localStorage.getItem('id');

  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(loginSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  useEffect(() => {
    if (saveId !== null) {
      setValue('userId', saveId || '');
      setIsSaveId(true);
    }
  }, [saveId]);

  useEffect(() => {
    if (values.userId !== '' && values.userPw !== '') {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [values.userId, values.userPw]);

  const handleLogin = async () => {
    await authApi
      .login({
        userEmail: values.userId,
        userPassword: values.userPw,
        autoLogin: autoLogin,
        rememberId: isSaveId,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate('/', { replace: true });
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'invalid username') {
          SetErrorBar('회원정보가 없습니다. 회원가입을 해주세요.');
        }
        if (err.response.data.message === 'invalid password') {
          SetErrorBar('비밀번호가 틀렸습니다. 다시 입력해 주세요.');
        }
      });
  };

  return (
    <Layout>
      <LoginStyled>
        <div className='login-inner'>
          <h2>로그인</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleLogin();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='userId'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='이메일'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='userPw'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='password'
                    multiline={false}
                    fullWidth={false}
                    placeholder='비밀번호'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='side-sec'>
              <div className='checkbox-box'>
                <Checkbox
                  checked={autoLogin}
                  onChange={(e) => setAutoLogin(e.target.checked)}
                >
                  자동로그인
                </Checkbox>
                <Checkbox
                  checked={isSaveId}
                  onChange={(e) => setIsSaveId(e.target.checked)}
                >
                  아이디 기억
                </Checkbox>
              </div>
              <div className='search-password'>
                <button
                  type='button'
                  onClick={() => navigate('/login/searchpw')}
                >
                  비밀번호 찾기
                </button>
              </div>
            </div>
            {isAbledBtn ? (
              <Button variant='' preset={0} type='submit' fullWidth>
                로그인
              </Button>
            ) : (
              <Button variant='' preset={0} type='submit' disabled fullWidth>
                로그인
              </Button>
            )}
          </form>
        </div>
      </LoginStyled>
    </Layout>
  );
};

const LoginStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 20px 0 20px;

  & .login-inner {
    height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .checkbox-box {
    display: flex;
    & label {
      margin-right: 30px;
    }
  }

  & .side-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }

  & .search-password {
    button {
      font-size: 16px;
      color: gray;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

export default Login;
