import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import request from "api/request";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Layout from "layout/Layout";

import SetErrorBar from "utils/SetErrorBar";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Resume = () => {
  const navigate = useNavigate();
  const { principal } = useSelector((s: any) => s.auth);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [numPages, setNumPages] = useState<number | null>(null); // 총 페이지수
  const [pageNumber, setPageNumber] = useState<number>(1); // 현재 페이지
  const [pageScale, setPageScale] = useState<number>(1); // 페이지 스케일
  const [pdfFilePath, setPdfFilePath] = useState<any>();

  const targetStorage = localStorage.getItem("accessToken")
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem("accessToken");

  useEffect(() => {
    request("/resume", "get", { "content-type": "application/json" }, {})
      .then((res) => {
        setPdfFilePath(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDeleteResume = async () => {
    await request(
      `/resume/${pdfFilePath?.raw[0].id}`,
      "delete",
      { "content-type": "application/json" },
      {}
    )
      .then((res) => {
        if (res.status === 200) {
          SetErrorBar("이력서가 삭제 되었습니다");
          navigate("/resume", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    console.log(`numPages ${numPages}`);
    setNumPages(numPages);
  };

  return (
    <Layout>
      <ResumeStyled isMobile={isMobileWidth}>
        <div className="resume-wrap">
          <div className="page">
            <div className="page-text">
              Page {pageNumber} of {numPages}
            </div>
            <div>
              <button
                onClick={() => {
                  setPageNumber(pageNumber === 1 ? pageNumber : pageNumber - 1);
                }}
              >
                &lt;
              </button>
              <button
                onClick={() => {
                  setPageNumber(
                    numPages === pageNumber ? pageNumber : pageNumber + 1
                  );
                }}
              >
                &gt;
              </button>
            </div>
          </div>
          {accessToken && pdfFilePath?.raw_files.server_path && (
            <div className="btn-group">
              <div className="registBtn">
                <button
                  onClick={() => {
                    navigate("/adminpage", {
                      state: {
                        isUpdate: true,
                        resumeData: pdfFilePath,
                      },
                    });
                  }}
                >
                  수정
                </button>
              </div>
              <div className="registBtn deleteBtn">
                <button
                  onClick={() => {
                    const result = window.confirm("이력서를 삭제하시겠습니까?");
                    if (result) {
                      handleDeleteResume();
                    }
                  }}
                >
                  삭제
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="pdf">
          <Document
            file={
              pdfFilePath &&
              `https://portfolio-buket.s3.ap-northeast-2.amazonaws.com${pdfFilePath?.raw_files.server_path}`
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              width={isMobileWidth ? 350 : 1000}
              scale={pageScale}
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        </div>
      </ResumeStyled>
    </Layout>
  );
};

const ResumeStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  overflow-y: scroll;

  .page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .page div {
    margin-right: 20px;
  }
  .page button {
    width: 30px;
    height: 30px;
    font-size: 20px;
    margin: 0 5px;
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #fff;
  }

  & .btn-group {
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
  }

  & .registBtn {
    display: flex;
    margin-right: 15px;

    button {
      cursor: pointer;
      width: 80px;
      height: 35px;
      color: #fff;
      border-radius: 4px;
      background-color: #1f1852;
    }
  }

  & .deleteBtn button {
    background-color: #e40000;
  }

  .pdf {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 10px 0 0 0;
          .resume-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .page {
            margin-left: 10px;
          }
        `
      : css`
          padding: 20px 0 30px 20px;
          .page {
            position: fixed;
            z-index: 9999999;
            left: 62%;
            bottom: 50px;
            transform: translateX(-50%);
          }
          .page-text {
            background-color: #fff;
          }
        `}
`;

export default Resume;
