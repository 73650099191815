import { Method } from 'axios';
import instance from './instance';

const request = async (
  url: string,
  method: Method,
  headers = {},
  jsonData: any
) => {
  if (method === 'get') {
    try {
      const { data } = await instance({
        method: method,
        url: url,
        headers: {
          ...headers,
        },
        params: jsonData,
      });

      return data;
    } catch (error) {
      // console.log('request function error', error, url);
      await Promise.reject(error);
    }
  } else {
    try {
      const data = await instance({
        method: method,
        url: url,
        headers: {
          ...headers,
        },
        data: jsonData,
      });
      return data;
    } catch (error) {
      // console.log('request function error', error, url);
      await Promise.reject(error);
    }
  }
};

export default request;
