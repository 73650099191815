import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import request from 'api/request';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Layout from 'layout/Layout';

import Box from 'components/Box/Box';

const Works = () => {
  const navigate = useNavigate();
  const [serchParams, setSearchParams] = useSearchParams();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [totalCount, setTotalCount] = useState(0);
  const [resultData, setResultData] = useState<any[]>([]);
  const queryString = serchParams.get('type');
  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    handleGetPortfolio();
  }, []);

  const handleGetPortfolio = async () => {
    await request(
      '/portfolio',
      'get',
      { 'content-type': 'application/json' },
      { type: queryString }
    )
      .then((res) => {
        setResultData(res.result.data);
        setTotalCount(res.result.total_elements);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <WorksStyled>
        <div className='work-header'>
          <h3>총 {totalCount}개</h3>
          {accessToken && (
            <div className='registBtn'>
              <button
                onClick={() =>
                  navigate('/work-regist', {
                    state: {
                      type: queryString,
                    },
                  })
                }
              >
                등록
              </button>
            </div>
          )}
        </div>
        {isMobileWidth ? (
          <div className='contents-box'>
            {resultData.length > 0 ? (
              resultData.map((x, i) => {
                return (
                  <Box
                    key={i}
                    data={x}
                    w={'95'}
                    onClick={() =>
                      navigate(`/works/${x.id}`, {
                        state: {
                          portfolioId: x.id,
                        },
                      })
                    }
                  />
                );
              })
            ) : (
              <div className='empty-content'>
                등록된 프로젝트가 없습니다.
                <br />
                프로젝트를 등록해주세요.
              </div>
            )}
          </div>
        ) : (
          <div className='contents-box'>
            {resultData.length > 0 ? (
              resultData.map((x, i) => {
                return (
                  <Box
                    key={i}
                    data={x}
                    w={'31'}
                    onClick={() =>
                      navigate(`/works/${x.id}`, {
                        state: {
                          portfolioId: x.id,
                        },
                      })
                    }
                  />
                );
              })
            ) : (
              <div className='empty-content'>
                등록된 프로젝트가 없습니다.
                <br />
                프로젝트를 등록해주세요.
              </div>
            )}
          </div>
        )}
      </WorksStyled>
    </Layout>
  );
};

const WorksStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 20px 0 20px;
  overflow-y: scroll;

  & .work-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .empty-content {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
  }

  & .contents-box {
    display: flex;
    flex-wrap: wrap;
  }

  & .registBtn {
    display: flex;
    justify-content: end;
    margin-right: 10px;
    margin-bottom: 10px;

    button {
      cursor: pointer;
      width: 80px;
      height: 35px;
      color: #fff;
      border-radius: 4px;
      background-color: #1f1852;
    }
  }
`;

export default Works;
