/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { forwardRef } from 'react';

import BtnCheckOnPNG from 'assets/images/btn_check_on.png';
import BtnCheckOffPNG from 'assets/images/btn_check_off.png';

interface CheckboxProps {
  checked: boolean;
  onChange: (e: any) => void;
  errors?: any;
  children: React.ReactNode;
  fontWegiht?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { checked, onChange, children, errors, fontWegiht, ...rest } = props;

  const root = rootCss(props);
  const input = inputCss(props);
  return (
    <>
      <label css={root} className='component-checkbox'>
        <input
          ref={ref}
          css={input}
          type='checkbox'
          onChange={(e) => onChange(e)}
          checked={checked}
          {...rest}
        />
        {children}
      </label>
    </>
  );
});

const rootCss = (props: CheckboxProps) => {
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;
};

const inputCss = (props: CheckboxProps) => {
  return css`
    appearance: none;
    margin-right: 8px;
    width: 20px;
    height: 20px;
    background: url(${BtnCheckOffPNG}) no-repeat center/100%;
    cursor: pointer;

    &:checked {
      background: url(${BtnCheckOnPNG}) no-repeat center/100%;
    }
  `;
};
export default Checkbox;
