import React, { useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import request from 'api/request';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setUiValue } from 'store/ui';
import { setIsChat } from 'store/chat';
import { setIsAlert } from 'store/alert';
import { setIsMypage } from 'store/auth';

import authApi from 'api/auth';

import Chat from 'pages/Chat/Chat';
import MypageMenu from 'components/MypageMenu/MypageMenu';
import AlertMessage from 'components/AlertMessage/AlertMessage';

import Logo from 'assets/images/logo.png';
import message from 'assets/images/messenger.png';
import alert from 'assets/images/notification.png';
import alertnoti from 'assets/images/notification-alert.png';
import menu from 'assets/images/menu.png';
import close from 'assets/images/close.png';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useSelector((s: any) => s.auth);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const { isChat } = useSelector((s: any) => s.chat);
  const { isAlert } = useSelector((s: any) => s.alert);
  const { isMypage } = useSelector((s: any) => s.auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [alertsData, setAlertsData] = useState([]);

  const targetStorage = localStorage.getItem('accessToken')
    ? localStorage
    : sessionStorage;
  const accessToken = targetStorage.getItem('accessToken');

  useEffect(() => {
    if (accessToken) handleAlerts();
  }, [accessToken]);

  const unreadAlerts = useMemo(() => {
    if (!alertsData) return [];
    return alertsData?.map((v: any) => v.is_readed);
  }, [alertsData]);

  const handleAlerts = async () => {
    await request('/alerts', 'get', { 'content-type': 'application/json' }, {})
      .then((res) => {
        if (res.result) {
          setAlertsData(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isMobileWidth ? (
        <HeaderStyled>
          <h1
            onClick={() => {
              navigate('/');
              dispatch(setUiValue('Home'));
            }}
          >
            <img src={Logo} alt='로고이미지' />
          </h1>
          <div className='mo-wrap'>
            {accessToken && (
              <ul className='mo-icon'>
                <li
                  onClick={() => {
                    if (isChat) {
                      dispatch(setIsChat(false));
                    } else {
                      dispatch(setIsChat(true));
                    }
                    if (isAlert) dispatch(setIsAlert(false));
                    if (isMypage) dispatch(setIsMypage(false));
                  }}
                >
                  <img src={message} alt='채팅 로고 이미지' />
                </li>
                <li
                  className='alertImg'
                  onClick={() => {
                    if (isAlert) {
                      dispatch(setIsAlert(false));
                    } else {
                      dispatch(setIsAlert(true));
                    }
                    if (isChat) dispatch(setIsChat(false));
                    if (isMypage) dispatch(setIsMypage(false));
                  }}
                >
                  <img
                    src={unreadAlerts.includes(false) ? alertnoti : alert}
                    alt='알림 로고 이미지'
                  />
                </li>
              </ul>
            )}
            <div className='menu-icon' onClick={() => setIsMenuOpen(true)}>
              <img src={menu} alt='메뉴 이미지' />
            </div>
          </div>
          {accessToken
            ? isMenuOpen && (
                <div className='mo-signedlist'>
                  <div className='mo-inner1'>
                    <h3>
                      <span>{name}</span>님 환영합니다.
                    </h3>
                    <div onClick={() => setIsMenuOpen(false)}>
                      <img src={close} alt='메뉴 닫기 이미지' />
                    </div>
                  </div>
                  <ul className='mo-menulist'>
                    <li
                      onClick={() => {
                        navigate('/works?type=REACT', {
                          state: {
                            type: 'REACT',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      React
                    </li>
                    <li
                      onClick={() => {
                        navigate('/works?type=NODE', {
                          state: {
                            type: 'NODE',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      Node
                    </li>
                    <li
                      onClick={() => {
                        navigate('/works?type=RESEARCH', {
                          state: {
                            type: 'RESEARCH',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      Research
                    </li>
                    <li
                      onClick={() => {
                        navigate('/resume');
                        dispatch(setUiValue('Resume'));
                      }}
                    >
                      Resume
                    </li>
                    <li
                      onClick={() => {
                        navigate('/friends');
                        dispatch(setUiValue('Friends'));
                      }}
                    >
                      Friends
                    </li>
                    <li
                      onClick={() => {
                        navigate('/mypage');
                      }}
                    >
                      회원정보 수정
                    </li>
                    <li
                      onClick={() => {
                        navigate('/adminpage', {
                          state: {
                            isUpdate: false,
                          },
                        });
                      }}
                    >
                      관리자 페이지
                    </li>
                  </ul>
                  <div
                    className='mo-logout'
                    onClick={() => {
                      authApi.logout();
                      navigate('/login', { replace: true });
                    }}
                  >
                    로그아웃
                  </div>
                </div>
              )
            : isMenuOpen && (
                <div className='mo-signedlist'>
                  <div className='mo-inner1'>
                    <ul className='mo-join'>
                      <li
                        onClick={() => {
                          navigate('/login');
                          dispatch(setUiValue('Login'));
                        }}
                      >
                        로그인
                      </li>
                      <li
                        onClick={() => {
                          navigate('/sign');
                          dispatch(setUiValue('Sign'));
                        }}
                      >
                        회원가입
                      </li>
                    </ul>
                    <div onClick={() => setIsMenuOpen(false)}>
                      <img src={close} alt='메뉴 닫기 이미지' />
                    </div>
                  </div>
                  <ul className='mo-menulist'>
                    <li
                      onClick={() => {
                        navigate('/works?type=REACT', {
                          state: {
                            type: 'REACT',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      React
                    </li>
                    <li
                      onClick={() => {
                        navigate('/works?type=NODE', {
                          state: {
                            type: 'NODE',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      Node
                    </li>
                    <li
                      onClick={() => {
                        navigate('/works?type=RESEARCH', {
                          state: {
                            type: 'RESEARCH',
                          },
                        });
                        dispatch(setUiValue('Works'));
                      }}
                    >
                      Research
                    </li>
                    <li
                      onClick={() => {
                        navigate('/resume');
                        dispatch(setUiValue('Resume'));
                      }}
                    >
                      Resume
                    </li>
                    <li
                      onClick={() => {
                        navigate('/friends');
                        dispatch(setUiValue('Friends'));
                      }}
                    >
                      Friends
                    </li>
                  </ul>
                </div>
              )}
          {isChat && <Chat />}
          {isAlert && (
            <AlertMessage handleAlerts={handleAlerts} alertsData={alertsData} />
          )}
        </HeaderStyled>
      ) : (
        <HeaderStyled>
          <h1
            onClick={() => {
              navigate('/');
              dispatch(setUiValue('Home'));
            }}
          >
            <img src={Logo} alt='로고이미지' />
          </h1>
          {accessToken ? (
            <ul className='signedlist'>
              <li
                onClick={() => {
                  if (isChat) {
                    dispatch(setIsChat(false));
                  } else {
                    dispatch(setIsChat(true));
                  }
                  if (isAlert) dispatch(setIsAlert(false));
                  if (isMypage) dispatch(setIsMypage(false));
                }}
              >
                <img src={message} alt='채팅 로고 이미지' />
              </li>
              <li
                className='alertImg'
                onClick={() => {
                  if (isAlert) {
                    dispatch(setIsAlert(false));
                  } else {
                    dispatch(setIsAlert(true));
                  }
                  if (isChat) dispatch(setIsChat(false));
                  if (isMypage) dispatch(setIsMypage(false));
                }}
              >
                <img
                  src={unreadAlerts.includes(false) ? alertnoti : alert}
                  alt='알림 로고 이미지'
                />
              </li>
              <li
                onClick={() => {
                  if (isMypage) {
                    dispatch(setIsMypage(false));
                  } else {
                    dispatch(setIsMypage(true));
                  }
                  if (isChat) dispatch(setIsChat(false));
                  if (isAlert) dispatch(setIsAlert(false));
                }}
              >
                <span>{name}</span>님 환영합니다.
              </li>
              <li
                onClick={() => {
                  authApi.logout();
                  navigate('/login', { replace: true });
                }}
              >
                로그아웃
              </li>
            </ul>
          ) : (
            <ul className='signlist'>
              <li
                onClick={() => {
                  navigate('/login');
                  dispatch(setUiValue('Login'));
                }}
              >
                로그인
              </li>
              <li
                onClick={() => {
                  navigate('/sign');
                  dispatch(setUiValue('Sign'));
                }}
              >
                회원가입
              </li>
            </ul>
          )}
          {isChat && <Chat />}
          {isAlert && (
            <AlertMessage handleAlerts={handleAlerts} alertsData={alertsData} />
          )}
          {isMypage && <MypageMenu />}
        </HeaderStyled>
      )}
    </>
  );
};

const HeaderStyled = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid #d9d9d9;
  h1 {
    cursor: pointer;
  }
  h1 img {
    width: 1.5em;
  }
  nav {
    width: 30em;
    margin-left: 10em;
    ul {
      display: flex;
      & li {
        cursor: pointer;
      }
    }
  }
  .menu-icon img {
    display: block;
    width: 25px;
    height: 25px;
  }
  .menulist {
    justify-content: space-around;
  }
  .signlist {
    width: 8em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & li {
      cursor: pointer;
    }
    img {
      display: block;
      width: 21px;
      height: 21px;
    }
  }
  .signedlist {
    width: 20em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & li {
      cursor: pointer;
    }
    img {
      display: block;
      width: 21px;
      height: 21px;
    }
    span {
      font-weight: bold;
    }
  }
  .mo-signedlist {
    position: absolute;
    z-index: 99999999999;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100vh;

    img {
      display: block;
      width: 21px;
      height: 21px;
    }
    span {
      font-weight: bold;
    }
  }

  .mo-wrap {
    display: flex;
  }

  .mo-inner1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    border-bottom: 1px solid #d9d9d9;

    h3 {
      margin-top: 5px;
    }
  }

  .mo-menulist {
    padding: 20px 30px;

    li {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .alertImg img {
    width: 25px;
    height: 25px;
  }

  .mo-join {
    display: flex;
    margin-top: 5px;

    li {
      font-size: 20px;
      margin-right: 20px;
    }
  }

  .mo-icon {
    display: flex;

    li {
      margin-right: 30px;
    }
    img {
      display: block;
      width: 24px;
      height: 24px;
    }
    .alertImg img {
      width: 27px;
      height: 27px;
    }
  }

  .mo-logout {
    border-top: 1px solid #d9d9d9;
    color: red;
    font-size: 20px;
    margin-top: 100px;
    padding: 10px 30px;
  }
`;

export default Header;
