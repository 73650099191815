// import Button, { ButtonType } from './Button';
import Button from 'components/Button/Button';
import Modal, {
  ModalButtonWrap,
  ModalContainer,
  ModalText,
} from 'components/Modal/Modal';
// import useWindowSize from '@hooks/useWindowSize';
// import { isMobile, mobile } from '@utils/responsive';

interface ITextModal {
  content: React.ReactNode;
  open: boolean;
  onClose: () => any;
  onSubmit?: () => any;
  submitText?: string;
  containerStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
}

const TextModal = (props: ITextModal) => {
  // const { width } = useWindowSize();
  return (
    <Modal {...props}>
      <ModalContainer>
        <ModalText>{props?.content}</ModalText>
        <ModalButtonWrap>
          {props.onSubmit && (
            <Button
              variant=''
              preset={0}
              type='button'
              w={'100'}
              h={'48'}
              fSize={'14'}
              disabled
              onClick={props.onClose}
            >
              취소
            </Button>
          )}
          <Button
            variant=''
            preset={0}
            type='button'
            w={'100'}
            h={'48'}
            fSize={'14'}
            onClick={props?.onSubmit ? props.onSubmit : props.onClose}
          >
            {props?.submitText || '확인'}
          </Button>
        </ModalButtonWrap>
      </ModalContainer>
    </Modal>
  );
};

export default TextModal;
