import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

import Layout from 'layout/Layout';

const Main = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/works?type=REACT', {
      state: {
        type: 'REACT',
      },
    });
  }, []);

  return (
    <Layout>
      <MainStyled></MainStyled>
    </Layout>
  );
};

const MainStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 20px 0 0 20px;
  overflow-y: scroll;

  .introduce-sec {
    margin-top: 60px;

    div {
      height: 60px;
    }
  }
`;

export default Main;
