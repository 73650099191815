import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const { isMobileWidth } = useSelector((s: any) => s.ui);

  return (
    <>
      <Header />
      <Container>
        {!isMobileWidth && <SideBar />}
        {children}
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
`;

export default Layout;
