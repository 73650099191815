import request from "./request";

// 채팅 리스트 조회
const privateChatList = async () => {
  try {
    const data = await request(
      "/private-chats",
      "get",
      { "content-type": "application/json" },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// 상대와의 채팅 정보 조회
const privateChatInfo = async (userId: string) => {
  try {
    const data = await request(
      `/private-chats/${userId}`,
      "get",
      { "content-type": "application/json" },
      {}
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export default {
  privateChatList,
  privateChatInfo,
};
