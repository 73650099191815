import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import request from 'api/request';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import Layout from 'layout/Layout';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';
import ErrorsMessage from 'components/ErrorsMessage/ErrorsMessage';

import registSchma from 'utils/validation/registSchma';

import 'react-datepicker/dist/react-datepicker.css';

interface IForm {
  title: string;
  position: string;
  skill: string;
  startdate: Date | null;
  enddate: Date | null;
  contents: string;
  files: [];
}

const defaultValues: IForm = {
  title: '',
  position: '',
  skill: '',
  startdate: new Date(),
  enddate: new Date(),
  contents: '',
  files: [],
};

const WorkRegist = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [img, setImg] = useState<any>([]);
  const [skillArr, setSkillArr] = useState<string[]>();
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(registSchma),
    shouldFocusError: true,
  });
  watch();
  const values = getValues();

  // DatePicker default value null
  useEffect(() => {
    setValue('startdate', null);
    setValue('enddate', null);
  }, []);

  useEffect(() => {
    const arr = values.skill.split(',');
    setSkillArr(arr);
    setImg(values.files);
  }, [values.skill, values.files]);

  const handleSubmitForm = async () => {
    let attachment: any = [];

    if (img) {
      try {
        for (let i = 0; i < img.length; i++) {
          const file = img[i][0];
          const res = await request(
            '/files',
            'post',
            { 'Content-Type': 'multipart/form-data' },
            { file: file }
          );

          attachment.push(res.data.result.id);
        }
      } catch (error) {
        console.log(error);
      }
    }

    let payload: any = {
      type: location?.state.type,
      title: values.title,
      position: values.position,
      skils: values.skill,
      started_at: values.startdate,
      end_at: values.enddate,
      detail: values.contents,
    };
    if (attachment) {
      payload = {
        ...payload,
        attachments: attachment,
      };
    }

    try {
      await request(
        '/portfolio',
        'post',
        { 'content-type': 'application/json' },
        payload
      )
        .then(() => {
          navigate(`/works?type=${location?.state.type}`, {
            state: {
              type: location?.state.type,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <WorkRegistStyled>
        <div className='regist-inner'>
          <h2>프로젝트 등록</h2>
          <form
            className={isMobileWidth ? 'mo-form-wrap' : 'form-wrap'}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleSubmitForm();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className='input-sec'>
              <Controller
                control={control}
                name='title'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='프로젝트 명'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 포지션'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='skill'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    multiline={false}
                    fullWidth={false}
                    placeholder='개발 스킬'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='datepicker-sec'>
              <Controller
                control={control}
                name='startdate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker datepicker1'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => {
                      field.onChange(date);
                    }}
                    placeholderText='시작일'
                  />
                )}
              />
              <span></span>
              <Controller
                control={control}
                name='enddate'
                render={({ field }) => (
                  <DatePicker
                    className='input-datepicker'
                    locale={ko}
                    dateFormat='yyyy.MM.dd'
                    selected={field.value}
                    onChange={(date: Date) => field.onChange(date)}
                    placeholderText='마감일'
                  />
                )}
              />
            </div>
            <div className='input-sec'>
              <Controller
                control={control}
                name='contents'
                render={({ field }) => (
                  <Input
                    {...field}
                    type='text'
                    fullWidth={false}
                    multiline={true}
                    useMaxLengthIndicator={true}
                    maxLength={1000}
                    placeholder='프로젝트 내용'
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className='sub-title'>
              첨부파일<span>(최대 7개까지 등록 가능합니다.)</span>
            </div>
            <div className='file-upload-wrap'>
              <Controller
                control={control}
                name='files'
                render={({ field }) => (
                  <FileUpload
                    img={field.value}
                    setImg={(e: any) => field.onChange(e)}
                    buttonStyle={uploadButton}
                    icon={'ic-plus'}
                    limit={7}
                    disabledPreview={false}
                  />
                )}
              />
            </div>
            {errors.files && <ErrorsMessage errors={errors} name='files' />}
            <Button variant='' preset={0} type='submit' fullWidth>
              등록하기
            </Button>
          </form>
        </div>
      </WorkRegistStyled>
    </Layout>
  );
};

const WorkRegistStyled = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #f1f1f1;
  padding: 40px 20px 60px 20px;
  overflow-y: scroll;

  & .regist-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .datepicker-sec {
    display: flex;
    margin: 10px 0;
    span {
      width: 20px;
    }
  }

  & .input-datepicker {
    width: 100%;
    height: 65px;
    padding-left: 20px;
    font-size: 16px;
    border: solid 1px #ddd;

    &::placeholder {
      color: #b2b2b2;
    }
  }

  & .sub-title {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #222;
    margin-top: 15px;

    span {
      font-size: 13px;
      font-weight: normal;
      letter-spacing: -0.26px;
      color: #999;
      margin-left: 4px;
    }
  }

  & .file-upload-wrap {
    display: flex;
    justify-content: flex-start;
    margin-right: auto;
    margin-bottom: 20px;
  }
`;

const uploadButton = {
  width: '100px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '20 0',
  borderRadius: '12px',
  border: 'solid 1px #000',
  backgroundColor: '#fff',
  marginRight: '20px',
};

export default WorkRegist;
