import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import request from "api/request";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import authApi from "api/auth";

import Layout from "layout/Layout";

import mypageSchema from "utils/validation/mypageSchema";
import { PREFIX_NUMBER_OPTIONS, POSITIONS_OPTIONS } from "utils/constants";

import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import SetErrorBar from "utils/SetErrorBar";
import FileInput from "components/FileInput/FileInput";

interface IForm {
  profile: [];
  email: string;
  password: string;
  passwordCheck: string;
  name: string;
  userPhone1: {
    value: string;
    label: string;
    code: string;
  };
  userPhone2: string;
  position: {
    value: string;
    label: string;
    code: string;
  };
}

const defaultValues: IForm = {
  profile: [],
  email: "",
  password: "",
  passwordCheck: "",
  name: "",
  userPhone1: {
    value: "",
    label: "",
    code: "",
  },
  userPhone2: "",
  position: {
    value: "",
    label: "",
    code: "",
  },
};

const Mypage = () => {
  const navigate = useNavigate();
  const { userId } = useSelector((s: any) => s.auth);
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [img, setImg] = useState<any>([]);
  const [loginInfo, setLoginInfo] = useState<any>();
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(mypageSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();

  useEffect(() => {
    if (userId !== 0) handleUserInfo();
  }, [userId]);

  useEffect(() => {
    setValue("email", loginInfo?.username);
    setValue("name", loginInfo?.name);
    const phone1 = loginInfo?.phone.substr(0, 3);
    const phone2 = loginInfo?.phone.substr(3, 8);
    for (let i = 0; i < PREFIX_NUMBER_OPTIONS.length; i++) {
      if (PREFIX_NUMBER_OPTIONS[i].value === phone1) {
        setValue("userPhone1", PREFIX_NUMBER_OPTIONS[i]);
      }
    }
    setValue("userPhone2", phone2);
    for (let i = 0; i < POSITIONS_OPTIONS.length; i++) {
      if (POSITIONS_OPTIONS[i].value === loginInfo?.position) {
        setValue("position", POSITIONS_OPTIONS[i]);
      }
    }
  }, [
    loginInfo?.username,
    loginInfo?.name,
    loginInfo?.phone,
    loginInfo?.position,
  ]);

  useEffect(() => {
    if (
      values.email !== "" &&
      values.name !== "" &&
      values.userPhone1.value !== "" &&
      values.userPhone2 !== "" &&
      values.position.value !== ""
    ) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [
    values.email,
    values.name,
    values.userPhone1.value,
    values.userPhone2,
    values.position.value,
  ]);

  useEffect(() => {
    setImg(values.profile);
  }, [values.profile]);

  const handleUserInfo = async () => {
    await request(
      `/users/${userId}`,
      "get",
      { "content-type": "application/json" },
      { userId: userId }
    )
      .then((res) => {
        setLoginInfo(res.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserInfoModify = async () => {
    let imgAttachment: any = [];

    if (img.length > 0) {
      try {
        const imgfile = img[0];
        const res = await request(
          "/files",
          "post",
          { "Content-Type": "multipart/form-data" },
          { file: imgfile }
        );

        imgAttachment.push(res.data.result.id);
      } catch (err) {
        console.log(err);
      }
    }

    let payload: any = {
      username: values.email,
      name: values.name,
      phone: values.userPhone1.value + values.userPhone2,
      position: values.position.value,
    };
    if (values.password) {
      payload = {
        ...payload,
        password: values.password,
      };
    }
    if (imgAttachment) {
      payload = {
        ...payload,
        profile_image_id: imgAttachment[0],
      };
    }

    try {
      await request(
        `/auth/login`,
        "put",
        { "content-type": "application/json" },
        payload
      )
        .then((res) => {
          if (res.status === 200) {
            if (values.password) {
              SetErrorBar("회원정보가 수정되었습니다. 재로그인해주세요.");
              authApi.logout();
              navigate("/login", { replace: true });
            } else {
              SetErrorBar("회원정보가 수정되었습니다.");
              navigate("/", { replace: true });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <MypageStyled isMobile={isMobileWidth}>
        <div className={isMobileWidth ? "mo-mypage-inner" : "mypage-inner"}>
          <h2>회원정보 수정</h2>
          <form
            className={isMobileWidth ? "mo-form-wrap" : "form-wrap"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  handleUserInfoModify();
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="input-sec">
              <Controller
                control={control}
                name="profile"
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? "250" : ""}
                    accept={"image/*"}
                    filename={
                      loginInfo?.files !== null
                        ? loginInfo?.files.file_name
                        : ""
                    }
                    fullWidth={false}
                    placeholder={"프로필 이미지를 첨부하세요."}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    placeholder="이메일"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="새 비밀번호"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="passwordCheck"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="새 비밀번호 확인"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    placeholder="이름"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="position"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="포지션 선택"
                    variant="primary"
                    fullWidth={true}
                    options={POSITIONS_OPTIONS}
                  />
                )}
              />
            </div>
            <div className="select-wrap">
              <div className="select-sec">
                <Controller
                  control={control}
                  name="userPhone1"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      options={PREFIX_NUMBER_OPTIONS}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="userPhone2"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      multiline={false}
                      fullWidth={false}
                      placeholder="핸드폰번호"
                      maxLength={8}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </div>
            {isAbledBtn ? (
              <Button variant="" preset={0} type="submit" fullWidth>
                회원정보 수정
              </Button>
            ) : (
              <Button variant="" preset={0} type="submit" disabled fullWidth>
                회원정보 수정
              </Button>
            )}
          </form>
        </div>
      </MypageStyled>
    </Layout>
  );
};

const MypageStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  background-color: #f1f1f1;

  & .mypage-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mo-mypage-inner {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .select-wrap {
    width: 100%;
    display: flex;
    margin: 10px 0;
  }

  & .select-sec {
    display: flex;
    width: 100%;
  }

  & .send-message {
    width: 120px;
    height: 65px;
    background-color: #1f1852;
    color: #fff;
    font-size: 18px;
    margin-left: 8px;
    cursor: pointer;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 50px 20px 100px 20px;
        `
      : css`
          padding: 50px 20px;
          height: calc(100vh - 80px);
          overflow-y: scroll;
        `}
`;

export default Mypage;
