import React from 'react';
import styled from '@emotion/styled';

import Layout from 'layout/Layout';

const Contact = () => {
  return (
    <Layout>
      <ContactStyled>Contact</ContactStyled>
    </Layout>
  );
};

const ContactStyled = styled.div`
  width: 100%;

  background-color: #f1f1f1;

  padding: 20px 0 0 20px;
`;

export default Contact;
