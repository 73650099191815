import styled from '@emotion/styled';
import { CSSProperties } from 'react';

import DefaultProfile from 'assets/images/default-profile.jpeg';

interface IProfileImage {
  width: number;
  height: number;
  fileId: number;
  containerStyle: CSSProperties;
}

const ProfilesImage = ({
  width,
  height,
  fileId,
  containerStyle,
}: IProfileImage) => {
  return (
    <ProfileImage
      width={width}
      height={height}
      fileId={fileId}
      style={containerStyle}
      url={DefaultProfile}
    />
  );
};

const ProfileImage = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  border: solid 1px #e3e3e3;
  border-radius: 100px;

  background-position: center;
  background-size: cover;

  background-image: ${(props: { url: string | null; fileId: number }) =>
    props.fileId
      ? `url(${process.env.REACT_APP_API}/files/${props.fileId})`
      : `url('${props.url}')`};
`;

export default ProfilesImage;
