import { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import request from "api/request";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Layout from "layout/Layout";

import signSchema from "utils/validation/signSchema";
import { PREFIX_NUMBER_OPTIONS, POSITIONS_OPTIONS } from "utils/constants";

import Input from "components/Input/Input";
import Button from "components/Button/Button";
import Select from "components/Select/Select";
import SetErrorBar from "utils/SetErrorBar";
import FileInput from "components/FileInput/FileInput";

enum TabTypeEnum {
  EMAILABLED,
  EMAILDISABLED,
  VERIFYABLED,
  VERIFYDISABLED,
}

interface IForm {
  profile: [];
  email: string;
  emailVerify: string;
  password: string;
  passwordCheck: string;
  name: string;
  position: string;
  userPhone1: string;
  userPhone2: string;
}

const defaultValues: IForm = {
  profile: [],
  email: "",
  emailVerify: "",
  password: "",
  passwordCheck: "",
  name: "",
  position: "",
  userPhone1: "",
  userPhone2: "",
};

const Sign = () => {
  const navigate = useNavigate();
  const { isMobileWidth } = useSelector((s: any) => s.ui);
  const [img, setImg] = useState<any>([]);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isEmailVerify, setIsEmailVerify] = useState<boolean>(false);
  const [isAbledBtn, setIsAbledBtn] = useState<boolean>(false);
  const [emailTabType, setEmailTabType] = useState<TabTypeEnum>(
    TabTypeEnum.EMAILABLED
  );
  const [verifyTabType, setVerifyTabType] = useState<TabTypeEnum>(
    TabTypeEnum.VERIFYABLED
  );
  const {
    register,
    handleSubmit,
    setError,
    setFocus,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues,
    resolver: yupResolver(signSchema),
    shouldFocusError: true,
  });
  watch();
  const values: any = getValues();

  useEffect(() => {
    if (
      values.email !== "" &&
      values.password !== "" &&
      values.passwordCheck !== "" &&
      values.name !== "" &&
      values.userPhone1.value !== "" &&
      values.userPhone2 !== "" &&
      values.position.value !== ""
    ) {
      setIsAbledBtn(true);
    } else {
      setIsAbledBtn(false);
    }
  }, [
    values.email,
    values.password,
    values.passwordCheck,
    values.name,
    values.userPhone1.value,
    values.userPhone2,
    values.position.value,
  ]);

  useEffect(() => {
    setImg(values.profile);
  }, [values.profile]);

  const handleRegister = async () => {
    let attachment: any = [];

    if (img) {
      try {
        const file = img[0];
        const res = await request(
          "/files",
          "post",
          { "Content-Type": "multipart/form-data" },
          { file: file }
        );

        attachment.push(res.data.result.id);
      } catch (err) {
        console.log(err);
      }
    }

    try {
      await request(
        "/auth/register",
        "post",
        { "content-type": "application/json" },
        {
          username: values.email,
          password: values.password,
          name: values.name,
          phone: values.userPhone1.value + values.userPhone2,
          position: values.position.value,
          profile_image_id: attachment[0],
        }
      )
        .then((res) => {
          if (res.status === 200) {
            navigate("/login", { replace: true });
          }
        })
        .catch((err) => {
          if (err.response.data.message === "duplicated email") {
            SetErrorBar("중복된 이메일입니다. 다른 이메일을 사용해주세요.");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailSubmit = async () => {
    if (values.email) {
      await request(
        "verifications/email",
        "post",
        { "content-type": "application/json" },
        { email: values.email }
      )
        .then((res) => {
          // console.log(res);
          if (res.data.result === true) {
            SetErrorBar("인증 메일이 전송되었습니다.");
            setIsEmail(true);
            setEmailTabType(TabTypeEnum.EMAILDISABLED);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setError(
        "email",
        { type: "focus", message: "이메일 주소를 입력하세요." },
        { shouldFocus: true }
      );
    }
  };

  const handleEmailVerify = async () => {
    if (isEmail) {
      await request(
        "verifications/email/verify",
        "post",
        { "content-type": "application/json" },
        {
          email: values.email,
          key: values.emailVerify,
        }
      )
        .then((res) => {
          if (res.data.result === true) {
            SetErrorBar("이메일이 인증되었습니다.");
            setIsEmailVerify(true);
            setVerifyTabType(TabTypeEnum.VERIFYDISABLED);
          }
        })
        .catch((err) => {
          if (err.response.data.message === "key is required") {
            SetErrorBar("인증번호를 입력하세요.");
          }
          if (err.response.data.message === "not matched") {
            SetErrorBar("인증번호가 틀렸습니다.");
          }
        });
    } else {
      SetErrorBar("이메일 인증번호 전송을 해주세요.");
      setError(
        "email",
        { type: "focus", message: "이메일 주소를 입력하세요." },
        { shouldFocus: true }
      );
    }
  };

  return (
    <Layout>
      <SignStyled isMobile={isMobileWidth}>
        <div className="sign-inner">
          <h2>회원가입</h2>
          <form
            className={isMobileWidth ? "mo-form-wrap" : "form-wrap"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(
                () => {
                  if (isEmailVerify) {
                    handleRegister();
                  } else {
                    SetErrorBar("이메일 인증을 먼저 진행해주세요.");
                  }
                },
                (err) => {
                  console.log(err);
                }
              )();
            }}
          >
            <div className="input-sec">
              <Controller
                control={control}
                name="profile"
                render={({ field }) => (
                  <FileInput
                    {...field}
                    w={isMobileWidth ? "253" : ""}
                    accept={"image/*"}
                    fullWidth={false}
                    errors={errors}
                    placeholder={"프로필 이미지를 첨부하세요."}
                  />
                )}
              />
            </div>
            <div className="select-wrap">
              <div className="select-sec">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      multiline={false}
                      fullWidth={false}
                      placeholder="이메일"
                      errors={errors}
                      disabled={isEmail}
                    />
                  )}
                />
              </div>
              <EmailBtnStyled
                active={emailTabType === TabTypeEnum.EMAILDISABLED}
                type="button"
                className="send-email"
                onClick={handleEmailSubmit}
              >
                인증번호 전송
              </EmailBtnStyled>
            </div>
            <div className="select-wrap">
              <div className="select-sec">
                <Controller
                  control={control}
                  name="emailVerify"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      multiline={false}
                      fullWidth={false}
                      placeholder="이메일 인증번호"
                      maxLength={6}
                      errors={errors}
                      disabled={isEmailVerify}
                    />
                  )}
                />
              </div>
              <EmailBtnStyled
                active={verifyTabType === TabTypeEnum.VERIFYDISABLED}
                type="button"
                className="send-email"
                onClick={handleEmailVerify}
              >
                이메일 인증
              </EmailBtnStyled>
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="비밀번호"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="passwordCheck"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="password"
                    multiline={false}
                    fullWidth={false}
                    placeholder="비밀번호 확인"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    multiline={false}
                    fullWidth={false}
                    placeholder="이름"
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="input-sec">
              <Controller
                control={control}
                name="position"
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="포지션 선택"
                    variant="primary"
                    fullWidth={true}
                    options={POSITIONS_OPTIONS}
                    errors={errors}
                  />
                )}
              />
            </div>
            <div className="select-wrap">
              <div className="select-sec">
                <Controller
                  control={control}
                  name="userPhone1"
                  render={({ field }) => (
                    <Select
                      {...field}
                      placeholder="선택"
                      variant="primary"
                      options={PREFIX_NUMBER_OPTIONS}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="userPhone2"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      multiline={false}
                      fullWidth={false}
                      placeholder="핸드폰번호"
                      maxLength={8}
                      errors={errors}
                    />
                  )}
                />
              </div>
            </div>
            {isAbledBtn ? (
              <Button variant="" preset={0} type="submit" fullWidth>
                회원가입
              </Button>
            ) : (
              <Button variant="" preset={0} type="submit" disabled fullWidth>
                회원가입
              </Button>
            )}
          </form>
        </div>
      </SignStyled>
    </Layout>
  );
};

const SignStyled = styled.div<{ isMobile: boolean }>`
  width: 100%;
  background-color: #f1f1f1;

  & .sign-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .form-wrap {
    width: 500px;
  }

  & .input-sec {
    margin: 10px 0;
  }

  & .select-wrap {
    width: 100%;
    display: flex;
    margin: 10px 0;
  }

  & .select-sec {
    display: flex;
    width: 100%;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 50px 20px 100px 20px;
        `
      : css`
          padding: 50px 20px;
          height: calc(100vh - 80px);
          overflow-y: scroll;
        `}
`;

const EmailBtnStyled = styled.button<{ active: boolean }>`
  width: 120px;
  height: 65px;
  background-color: #1f1852;
  color: #fff;
  font-size: 15px;
  margin-left: 8px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          background-color: gray;
        `
      : css`
          background-color: #1f1852;
        `}
`;

export default Sign;
