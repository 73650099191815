export const getSocketHost = () => {
  return 'https://api.yunsikdev.store'; // 수정
};

export const ERROR_COLOR = '#ff4b4b';

export const PREFIX_NUMBER_OPTIONS = [
  { value: '010', label: '010', code: '01' },
  { value: '011', label: '011', code: '02' },
  { value: '016', label: '016', code: '03' },
  { value: '017', label: '017', code: '04' },
  { value: '019', label: '019', code: '05' },
];

export const POSITIONS_OPTIONS = [
  { value: '프론트엔드', label: '프론트엔드', code: '01' },
  { value: '백엔드', label: '백엔드', code: '02' },
  { value: '풀스텍', label: '풀스텍', code: '03' },
];
