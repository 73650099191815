import { configureStore, combineReducers } from '@reduxjs/toolkit';
import alertReducer from './alert';
import authReducer from './auth';
import uiReducer from './ui';
import chatReducer from './chat';

const rootReducer = combineReducers({
  alert: alertReducer,
  auth: authReducer,
  ui: uiReducer,
  chat: chatReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
